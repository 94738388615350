@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
}
.border-select:focus{
    border: 1px solid #7366F0  !important;
}
.text-font {
    font-family: "Montserrat", Helvetica, Arial, serif;
}
